<template>
  <div class="container-fluid pt-2 main-vitrine">
    <NavOption
      navLink="/developpement-web?itemActif=e-commerce"
      navText="Retour"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/ecommerce-pro.webp"
            alt="mockup-eshop"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Boostez vos ventes et améliorez votre visibilité, grâce au
            <span>e-commerce</span>
          </h1>
          <p class="p-1">
            Nous vous offrons bien plus qu'une simple plateforme de
            <strong>vente en ligne</strong>. Découvrez comment notre approche
            novatrice et nos technologies de pointe transforment votre
            expérience du <strong>e-commerce</strong>,
            <strong>boostant vos ventes en ligne</strong> et optimisant la
            gestion de votre <strong>boutique en ligne</strong>.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 6000€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">Vuejs + Symfony</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">
                À définir selon volume application
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>Créez sa boutique en ligne.</h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Créer un e-commerce Professionnel"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              Un <strong>e-commerce professionnel</strong> représente bien plus
              qu'une simple <strong>plateforme de vente en ligne</strong>. C'est
              une <strong>vitrine digitale</strong> qui ouvre des portes vers de
              nouveaux marchés. Il permet d'atteindre une audience plus large,
              de faciliter les transactions, et de stimuler la croissance d'une
              entreprise. Notre expertise dans le
              <strong>développement d'e-commerce</strong> vous garantit une
              <strong>expérience utilisateur fluide et sécurisée</strong>,
              offrant une interface intuitive pour vos clients et vous
              permettant d'étendre votre présence sur le marché en ligne.
            </p>
          </div>
          <div class="col-lg-6">
            <div
              class="view overlay rounded z-depth-2 mb-2 mb-lg-0 slide-right"
            >
              <img
                src="@/assets/pictures/e-commerce-pro.webp"
                alt="mockup-site-web-ecommerce"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Un e-commerce haute performance"
              titleColor="#f27316"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Le choix de technologies de pointe pour nos
              <strong>e-commerces professionnels</strong>, avec le front-end
              utilisant Vue.js et le back-end basé sur Symfony6 (API),
              représente l'union parfaite entre performance, flexibilité et
              sécurité. Cette combinaison permet une gestion aisée de la
              plateforme tout en offrant une
              <strong>expérience utilisateur</strong> supérieure. La puissance
              de Vue.js offre une
              <strong>interface utilisateur</strong> réactive et moderne tandis
              que Symfony6 garantit une base solide, évolutive et sécurisée pour
              répondre aux besoins de votre entreprise.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-2 mb-lg-0 slide-left">
              <img
                src="@/assets/pictures/e-commerce-pro-watch.webp"
                alt="mockup-site-web-eshop"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="pro-ecommerce.webp"
      h3="Créer sa boutique en ligne"
      webServiceValue="E-commerce framework"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant la création d'un site e-commerce, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "EcommercePro",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Créer un site e-commerce",
      description: [
        {
          content:
            "Augmentez les ventes en ligne de votre entreprise avec notre service de création de e-commerce. Propulsez votre boutique en ligne vers le succès avec des solutions sur mesure pour une expérience d'achat convaincante et une gestion efficace.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-site-e-commerce",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-vitrine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: $color-primary;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset $color-primary;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 50px;
      font-size: 2em;
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 20px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
