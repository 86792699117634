<template>
  <div class="container-fluid pt-2 main-mobile">
    <NavOption
      navLink="/application-mobile"
      navText="Retour"
      navBorderColor="#800080"
      iconColorClass="purple"
    />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/mobile-store.webp"
            alt="logo-ios-android"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Développement d'applications mobiles <span>iOS/Android</span> avec
            React Native
          </h1>
          <p class="p-1">
            Découvrez la
            <strong>création d'applications mobiles</strong> natives
            performantes pour les plateformes <strong>iOS</strong> et
            <strong>Android</strong> grâce à l'utilisation de React Native. Des
            solutions innovantes et efficaces pour répondre aux besoins de votre
            entreprise, proposant une
            <strong>expérience utilisateur fluide</strong> et immersive à vos
            clients.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container-fluid">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 8000€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">React Native</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">
                À définir selon le type d'application
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>Créez une application mobile pour votre entreprise</h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Créations applications mobiles"
              titleColor="#ffc0cb"
            />
            <p class="text-left mx-auto w-responsive">
              Les <strong>applications mobiles iOS et Android</strong> sont
              aujourd'hui au cœur de la stratégie numérique de nombreuses
              entreprises. En exploitant les caractéristiques spécifiques de
              chaque plateforme, elles offrent une présence ciblée et optimisée
              pour atteindre un public diversifié. Ces
              <strong>applications mobiles</strong>, développées avec précision
              pour les utilisateurs d'<strong>iOS</strong> et
              d'<strong>Android</strong>, constituent un levier puissant pour la
              croissance et l'engagement des utilisateurs. Elles vous permettent
              de tirer parti des fonctionnalités exclusives de chaque système
              d'exploitation tout en offrant une
              <strong>expérience utilisateur</strong>
              exceptionnelle et uniforme, quel que soit le périphérique utilisé.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/developpement-mobile-agile.webp"
                alt="three-screen-mobile"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Des Applications Multiplateformes"
              titleColor="#ffc0cb"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              React Native représente une révolution dans le monde du
              <strong>développement d'applications mobiles</strong>. Cette
              technologie révolutionnaire permet de
              <strong>créer des applications iOS</strong> et
              <strong>Androïd</strong> avec un seul code source. En utilisant
              JavaScript, elle offre une flexibilité et une efficacité inégalées
              pour le
              <strong>déploiement rapide d'applications multiplateformes</strong
              >. Grâce à cette approche, les entreprises peuvent réduire
              considérablement les
              <strong>coûts de développement mobiles</strong> tout en
              garantissant une <strong>expérience utilisateur</strong> homogène
              et performante, que ce soit sur <strong>iOS</strong> ou sur
              <strong>Android</strong>. La modularité de React Native offre
              également une maintenance simplifiée et des mises à jour rapides,
              assurant ainsi une évolutivité continue pour les
              <strong>applications mobiles</strong> de votre entreprise.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-lg-0 mb-2 slide-left">
              <img
                src="@/assets/pictures/mobile-app-triple.webp"
                alt="three-mockup-mobile"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="ui-mobile.webp"
      h3="Application mobile"
      webServiceValue="Création application mobile"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant le développement d'une application mobile, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption btnBorderColor="purple" iconColorClass="purple" />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "IosAndroid",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Ios & Android",
      description: [
        {
          content:
            "Créez des applications iOS et Android avec une seule base de code grâce à React Native chez Malaweb. Exploitez la puissance de cette technologie pour développer des applications robustes, évolutives et compatibles avec les deux plateformes. Maximisez l'efficacité de votre développement d'applications mobiles tout en offrant une expérience utilisateur fluide sur iOS et Android.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/prix-application-mobile",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to left, #ffc0cb, #800080);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: #ffc0cb;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset #ffc0cb;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: #ffc0cb;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 50px;
      font-size: 2em;
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
