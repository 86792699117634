<template>
  <div class="container-fluid pt-2 main-vitrine">
    <NavOption navLink="/developpement-web?itemActif=saas" navText="Retour" />
    <section class="hero-banner container">
      <div class="flex">
        <div
          class="hero-banner__img col-lg-7 order-lg-2 d-flex justify-content-center"
        >
          <img
            src="@/assets/pictures/solutions-erp.webp"
            alt="paper-computer-statistic"
            class="delta"
            width="500"
          />
        </div>
        <div class="hero-banner__speech col-lg-5 mr-lg-5">
          <h1 class="mt-5">
            Transformez votre gestion d'entreprise avec notre <span>ERP</span>
          </h1>
          <p class="p-1">
            Gérez efficacement votre entreprise grâce à notre
            <strong>ERP</strong>. Ce système complet intègre divers processus,
            de la comptabilité à la gestion des stocks. Obtenez une vue
            d'ensemble optimisée de vos opérations et améliorez leur efficacité
            pour une croissance durable.
          </p>
          <!---<a href="#" class="btn btn-slide btn-lg border">en savoir plus</a>-->
        </div>
      </div>
    </section>
    <div class="pt-5">
      <section class="p-md-3 container">
        <div class="row-icon pt-3">
          <div class="row-icon__cadre mb-5">
            <i class="col-3 far fa-paper-plane indigo-text"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Prix</h4>
              <p class="text-muted mb-lg-0">À partir de 6000€</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Technologie</h4>
              <p class="text-muted mb-lg-0">Adaptée au projet</p>
            </div>
          </div>
          <div class="row-icon__cadre mb-5">
            <i class="far fa-paper-plane indigo-text col-3"></i>
            <div class="col-9">
              <h4 class="font-weight-bold mb-3">Hébergement</h4>
              <p class="text-muted mb-lg-0">
                À définir selon le type d'application
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <article class="container-fluid">
      <div class="article-title container-lg">
        <h2>Optimisez votre entreprise avec nos solutions ERP.</h2>
      </div>
      <section class="container mt-3 mt-lg-5">
        <div class="block-row pt-5">
          <div class="block-text col-lg-5 slide-left">
            <TitleColor
              title="Maximisez l'efficacité de votre entreprise"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              L'intégration d'un
              <strong
                >système de planification des ressources d'entreprise</strong
              >
              (<strong>ERP</strong>) est essentielle pour orchestrer et
              optimiser efficacement les processus internes. En déployant un
              <strong>ERP</strong>, vous centralisez la gestion des données, de
              la comptabilité à la gestion des stocks, en passant par les
              ressources humaines. Cette solution offre une vue panoramique,
              améliore la productivité, rationalise les opérations et renforce
              la prise de décision stratégique. En unifiant vos activités sous
              un même toit numérique, vous simplifiez les flux de travail,
              réduisez les erreurs et stimulez la collaboration interservices,
              permettant à votre entreprise de prospérer.
            </p>
          </div>
          <div class="col-lg-6">
            <div class="view overlay rounded z-depth-2 mb-0 slide-right">
              <img
                src="@/assets/pictures/erp-dashboard.webp"
                alt="dashboard-application-erp"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <!--Section: Content-->
      <section class="container my-5 pb-lg-5">
        <!-- Grid row -->
        <div class="block-row reverse">
          <!-- Grid column -->
          <div class="block-text slide-right col-lg-5">
            <!-- Category -->
            <!-- Post title -->
            <TitleColor
              title="Puissance Technologique pour un ERP Optimisé"
              titleColor="#f27316"
              bgColor="hsl(263, 47%, 58%)"
            />
            <!-- Excerpt -->
            <p>
              Lorsque vous optez pour nos <strong>solutions SaaS</strong> pour
              votre <strong>ERP</strong>, vous bénéficiez d'une fusion
              harmonieuse de technologies avancées. Que ce soit avec Vue.js
              couplé à Symfony ou via React Native, notre approche technologique
              repose sur des bases solides. L'utilisation de ces frameworks
              offre une <strong>interface utilisateur dynamique</strong>,
              réactive et intuitive. Cette combinaison technologique garantit
              une <strong>expérience utilisateur fluide</strong> et un accès
              sécurisé, tout en offrant une évolutivité exceptionnelle pour
              répondre aux besoins futurs de votre entreprise. Optez pour nos
              technologies avancées et propulsez votre ERP vers l'efficacité et
              la performance supérieure.
            </p>
          </div>
          <!-- Grid column -->
          <!-- Grid column -->
          <div class="col-lg-6">
            <!-- Featured image -->
            <div class="view overlay rounded z-depth-2 mb-0 slide-left">
              <img
                src="@/assets/pictures/erp-saas.webp"
                alt="mockup-erp-screen"
                class="img-fluid"
              />
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
    </article>
    <CallActionServiceSolo
      img="erp-digital-solutions.webp"
      h3="Développez un ERP pour votre entreprise"
      webServiceValue="ERP"
      h3Color="#ebdcdc"
      p="Sentez vous libre de nous contacter pour toute question concernant le développement d'un ERP pour votre entreprise, notre équipe vous apportera les réponses adaptées à
        votre projet web."
    />
    <FooterOption />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import TitleColor from "@/components/partials/TitleColor.vue";
import CallActionServiceSolo from "@/components/partials/CallActionServiceSolo.vue";
import NavOption from "@/components/NavOption.vue";
import FooterOption from "@/components/FooterOption.vue";
export default {
  name: "CrmSaas",
  components: {
    TitleColor,
    CallActionServiceSolo,
    NavOption,
    FooterOption,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(
        ".slide-right",
        ".slide-left",
        ".fall-down",
        ".image-fade-in"
      );
    });
  },
  setup() {
    useMeta({
      title: "Solutions ERP",
      description: [
        {
          content:
            "Optimisez vos processus métier avec nos solutions ERP personnalisées. Gérez efficacement vos ressources, améliorez la collaboration interne et maximisez l'efficacité opérationnelle avec nos solutions sur mesure.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.malaweb.fr/saas-erp",
        },
      ],
    });
  },
  created() {
    this.$root.showNavbar = false;
    this.$root.showFooter = false;
  },
  unmounted() {
    this.$root.showNavbar = true;
    this.$root.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.main-vitrine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);

  & .hero-banner {
    //@include background-svg;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    color: $color-text;
    padding: 40px 20px;
    @media screen and (min-width: 992px) {
      padding: 0px 0px 70px 20px;
    }
    & .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @media screen and (min-width: 992px) {
        display: flex;
        flex-direction: row;
      }
    }
    &__img {
      @include image-fade-in(2s);
      & img {
        @media screen and (max-width: 1245px) {
          width: 100%;
        }
      }
    }

    & .hero-banner__speech .btn {
      position: relative;
      overflow: hidden;
      z-index: 9;
    }
    & .hero-banner__speech .btn-slide {
      background-color: rgba(0, 0, 0, 0.1);
      color: $color-text;
    }
    & .hero-banner__speech .btn-slide:before {
      content: "";
      background: #f27316;
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
    }
    & .hero-banner__speech .btn-slide:hover:before {
      width: 100%;
    }

    & .hero-banner__speech {
      @media screen and (min-width: 992px) {
        padding-left: 50px;
      }
      & h1 > span {
        color: $color-darker;
      }
      & p {
        text-align: start;
      }
      & a {
        margin: 15px 0px 20px 0px;
      }
    }
  }
  & .row-icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &__cadre {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;
      min-height: 100%;
      padding: 10px 20px;
      text-align: center;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      border-radius: 59px;
      border: 0.1px outset $color-lighter;
      box-shadow: inset 20px 20px 60px #434343, inset -20px -20px 60px black;
      & i {
        color: $color-primary;
        font-size: 2em;
        border-radius: 100%;
        border: 0.5px outset $color-primary;
        padding: 18px;
      }
      & h4 {
        font-size: 1.3em;
        color: $color-primary;
      }
      & p {
        text-align: center;
        font-size: 0.9em;
      }
    }
  }
  & article {
    & h2 {
      text-align: center;
      color: $color-text;
      text-shadow: 4px -1px 2px rgba(0, 0, 0, 0.61);
      margin: 80px 10px 50px;
      font-size: 2em;
    }
  }
  .block-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & img {
      width: 500px;
      max-width: 90vw;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 0px;
        margin-top: 30px;
      }
    }
    & p {
      color: $color-text;
    }
    & .img-double {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .hr {
      width: 50px;
      height: 3px;
      background-color: $background;
    }
  }

  & .reverse {
    flex-direction: column-reverse;
    @media screen and (min-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }
  .callAction {
    margin: 20px 0px 0px 0px;
  }
}
</style>
