<template>
  <div class="container">
    <!--Section: Content-->
    <div class="mb-5 contactUs">
      <!-- Grid row -->
      <div class="row">
        <div class="col-12">
          <div class="card z-depth-2">
            <div class="card-body p-0">
              <div class="row mx-0 contactUs__first">
                <div class="col-lg-6 black rounded-left py-5 px-md-5">
                  <div>
                    <a href="tel:+33765721135" class="footer-icon">
                      <i class="fas fa-phone mx-2"></i>
                    </a>
                    <a href="sms:+33765721135" class="footer-icon">
                      <i class="fa fa-comments mx-2"> </i>
                    </a>
                    <a href="mailto:contact@malaweb.fr" class="footer-icon">
                      <i class="fa fa-envelope mx-2"> </i>
                    </a>
                  </div>
                  <div class="call-action__img">
                    <img
                      src="@/assets/brand/fox-sigle.webp"
                      alt="logo"
                      width="100"
                      class="mb-1"
                    />
                  </div>
                </div>
                <div
                  class="contactUs__second col-lg-6 red accent-3 rounded-right py-5 px-md-5"
                >
                  <h3 class="jaapokki">Mala<span>web</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid row -->
    </div>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "ContactUsAction",
};
</script>

<style lang="scss" scoped>
.contactUs {
  &:hover {
    & h3 {
      color: $color-text;
      & span {
        color: $color-secondary;
      }
    }
  }
  &__first {
    background: $color-darker;
    & i {
      font-size: 50px;
      color: $color-primary;
      background: rgba($color: $color-secondary, $alpha: 0.3);
      margin: auto;
      padding: 9px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        font-size: 50px;
      }
    }
    & .rounded-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media screen and (min-width: 992px) {
        flex-direction: row;
        align-items: center;
      }
      & img {
        background: $color-primary;
        border-radius: 50%;
        padding: 2px;
        @media screen and (max-width: 992px) {
          margin-top: 1.5rem;
        }
      }
    }
  }
  &__second {
    background: $color-primary;
    display: flex;
    justify-content: center;
    & h3 {
      font-size: 4em;
      color: $color-secondary;
      margin: auto;
      & span {
        color: $color-text;
        margin-right: 15px;
      }
      &:hover {
        color: $color-text;
        & span {
          color: $color-secondary;
        }
      }
    }
  }
}
</style>
